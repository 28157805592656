<template>
  <v-container
    v-if="primaryRep"
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <div class="title-text">
          Rep Assigned
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="4"
      >
        <RepInfo :rep="primaryRep" type="Primary" />
      </v-col>
      <v-col
        v-if="secondaryRep"
        cols="12"
        sm="6"
        lg="4"
      >
        <RepInfo :rep="secondaryRep" type="Secondary" />
      </v-col>
      <v-col
        v-if="tertiaryRep"
        cols="12"
        sm="6"
        lg="4"
      >
        <RepInfo :rep="tertiaryRep" type="Tertiary" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RepInfo from "./components/RepInfo";

export default {
  name: "RepManagementContactDataSection",
  components: {
    RepInfo,
  },
  props: {
    contact: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    primaryRep() {
      if (Array.isArray(this.contact?.rep ?? [])) {
        return null;
      }

      return {
        name: this.contact.rep.repFirstName + ' ' + this.contact.rep.repLastName,
        externalId: this.contact.rep.externalId,
        email: this.contact.rep.repEmail,
        phone: this.contact.rep.repPhone,
        territory: this.contact.rep.territory,
      };
    },
    secondaryRep() {
      if (Array.isArray(this.contact?.rep ?? []) || !this.contact.rep.secondaryRepEmail) {
        return null;
      }

      return {
        name: this.contact.rep.secondaryRepFirstName + ' ' + this.contact.rep.secondaryRepLastName,
        externalId: this.contact.rep.secondaryExternalId,
        email: this.contact.rep.secondaryRepEmail,
        phone: this.contact.rep.secondaryRepPhone,
      };
    },
    tertiaryRep() {
      if (Array.isArray(this.contact?.rep ?? []) || !this.contact.rep.tertiaryRepEmail) {
        return null;
      }

      return {
        name: this.contact.rep.tertiaryRepFirstName + ' ' + this.contact.rep.tertiaryRepLastName,
        externalId: this.contact.rep.tertiaryExternalId,
        email: this.contact.rep.tertiaryRepEmail,
        phone: this.contact.rep.tertiaryRepPhone,
      };
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
