<template>
  <div class="rep-info">
    <strong>{{ type }} Rep</strong><br>
    <strong>Name:</strong> {{ rep.name ? rep.name : 'N/A' }}<br>
    <strong>Email:</strong> {{ rep.email ? rep.email : 'N/A' }}<br>
    <strong>Phone:</strong> {{ rep.phone ? rep.phone : 'N/A' }}<br>
    <template v-if="rep.territory">
      <strong>Territory:</strong> {{ rep.territory }}
    </template>
  </div>
</template>

<script>
export default {
  name: "RepInfo",
  props: {
    rep: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.rep-info {
  font-size: 12px;
}
</style>
